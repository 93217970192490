import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import Layout from '../components/Layout';
import Post from '../components/Post';

class BlogPost extends React.Component {
	render() {
		const {data, pageContext} = this.props;
		const srcSet = data.markdownRemark.frontmatter.cover.childImageSharp.fluid.srcSet.split(',');
		const cover = data.site.siteMetadata.siteUrl + srcSet[3].split(' ')[0].trim();

		return (
			<Layout
				categories={pageContext.categories}
				seo={{cover, title : data.markdownRemark.frontmatter.title, description : data.markdownRemark.excerpt}}
				page={{next : null, previous : null}}
			>
				<Post post={data.markdownRemark}/>
			</Layout>
		);
	}
}

BlogPost.propTypes = {
	data : PropTypes.shape({
		site : PropTypes.object.isRequired,
		markdownRemark : PropTypes.object.isRequired
	}).isRequired,
	pageContext   : PropTypes.shape({
		categories : PropTypes.array.isRequired
	}).isRequired
};

export default BlogPost;
export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		site {
			siteMetadata {
				siteUrl
			}
	    }
		markdownRemark(
			fields: {slug: {eq: $slug}}
		) {
			excerpt
			html
			fields {
				slug
				readingTime {
					text
				}
			}
			frontmatter {
				date(formatString: "MMM D")
				title
				cover {
					childImageSharp {
						fluid(maxWidth: 1024) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;
